<template>
  <section>
    <folha v-for="(elementos, indexFolha) in tamanhoElemento" :key="indexFolha">
      <br /><br />
      <h3 v-if="via == '2' && indexFolha === 0">2ª VIA DO LAUDO</h3>
      <br /><br />
      <div v-if="indexFolha === 0">
        <div class="header maiuscula" style="">
          <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
          <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          <!-- <h3><strong>CONVÊNIO:</strong> {{ convenio.nome }}</h3> -->
          <br />
        </div>

        <br /><br />
        <h3>ULTRASSONOGRAFIA PÉLVICA TRANSABDOMINAL</h3>
        <br /><br />
      </div>

      <div v-for="(elemento, indexElemento) in elementos" :key="indexElemento">
        <h4 v-if="titulos.includes(elemento)">
          <p></p>
          <p></p>
          {{ elemento }}
          <p></p>
        </h4>
        <div v-else>
          {{ elemento }}
        </div>
      </div>
      <br />
      <br />
      <footer v-if="indexFolha + 1 == tamanhoElemento.length">
        <span
          ><strong
            >Dra
            {{
              medico.nome == 'Adriana Melo (curso)'
                ? 'Adriana Melo'
                : medico.nome
            }}</strong
          > </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>

    <!-- <h3></h3>
      <p></p>
      <p></p>

      <p>
        O útero está {{ laudo.uteroPosicao }} , {{ laudo.uteroSituacao }} , tem
        os contornos {{ laudo.uteroContornos }}
      </p>

      <p>O miométrio tem ecotextura {{ laudo.miometrioEcotextura }}</p>

      <p>
        Presença de {{ laudo.presenca }} imagens hipoecóicas no miométrio, assim
        distribuídas:
      </p>

      <p>
        {{ laudo.distribuicaoImagens }} medindo {{ laudo.medindo }} cm,
        localizado na {{ laudo.localizacao }}
      </p> -->

    <!-- {{ laudo.presencaDivisao }} -->
    <!-- <p></p>

      <h4>Diâmetros uterinos:</h4>

      <p>Longitudinal: {{ laudo.longitudinal }} cm.</p>

      <p>Ântero-posterior: {{ laudo.anteroPosterior }} cm.</p>

      <p>Transverso: {{ laudo.transverso }} cm.</p>

      <p>Volume: {{ laudo.volume }} cm.</p>

      <p>
        <span> {{ laudo.intracavitario }} </span>
        <span v-if="laudo.intracavitario === 'Imagem hiperecóica medidindo'">
          {{ laudo.intracavitarioCm }} cm, apresentando pedículo vascular ao
          Doppler.</span
        >
        {{ laudo.comentariosAdcionais8 }}
      </p>

      <p>O endométrio mede {{ laudo.endometrioMede }} mm de espessura.</p>
      <p></p>
      <h4>Ovários:</h4>

      <p>
        Ovário direito
        {{ laudo.ovarioDireito }}
        {{ laudo.comentariosAdcionais9 }} ,
        {{ laudo.ovarioDireito2 }}
        {{ laudo.comentariosAdcionais }}
      </p>




        <span
          v-if="
            laudo.imagemAnecoica4 ===
            'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/ Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
          "
        >
          {{ laudo.imagemAnecoica4 }} {{ laudo.imagemAnecoica4Cm }} cm no seu
          maior diâmetro.
        </span>

        <span
          v-if="
            laudo.imagemAnecoica4 ===
            'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
          "
        >
          {{ laudo.imagemAnecoica4 }} {{ laudo.finoSeptoCm4 }} cm no seu maior
          diâmetro.
        </span>

        <span
          v-if="
            laudo.imagemAnecoica4 ===
            'Apresenta imagem anecóica com debris, medindo'
          "
        >
          {{ laudo.imagemAnecoica4 }} {{ laudo.anecoicaDebrisCm4 }} cm no seu
          maior diâmetro.
        </span>

        <span
          v-if="
            laudo.imagemAnecoica4 ===
            'Apresenta imagem anecóica multiseptada medindo'
          "
        >
          {{ laudo.imagemAnecoica4 }} {{ laudo.anecoicaMultiseptadaCm4 }} cm no
          seu maior diâmetro.
        </span>

        <span
          v-if="
            laudo.imagemAnecoica4 === 'Apresenta imagem heterogênea medindo'
          "
        >
          {{ laudo.imagemAnecoica4 }} {{ laudo.anecoicaHeterogeneaCm45 }} x
          {{ laudo.imagemHeterogeneaCm4 }}cm no seu maior diâmetro.
        </span>

        <span v-if="laudo.imagemAnecoica4 === 'outro.'">
          {{ laudo.imagemHeterogeneaOutro4 }}
        </span>


      <p>
        Medidas do ovário direito: {{ laudo.medidasOvarioDireito1 }} x
        {{ laudo.medidasOvarioDireito2 }} x {{ laudo.medidasOvarioDireito3 }} cm
        e volume de cm³.
      </p>

      <p>
        Ovário esquerdo
        {{ laudo.ovarioEsquerdo }}
        {{ laudo.ovarioEsquerdoOutro }} ,
        {{ laudo.ovarioEsquerdo2 }}
        {{ laudo.ovarioEsquerdo2Outro }}
      </p>
      {{laudo.imagemAnecoica5}} -->
    <!--
      <p>

        <span
          v-if="
            laudo.imagemAnecoica5 ===
            'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/ Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
          "
        >
          {{ laudo.imagemAnecoica5 }} {{ laudo.imagemAnecoica5Cm }} cm no seu
          maior diâmetro.
        </span>

        <span
          v-if="
            laudo.imagemAnecoica5 ===
            'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
          "
        >
          {{ laudo.imagemAnecoica5 }} {{ laudo.finoSeptoCm5 }} cm no seu maior
          diâmetro.
        </span>

        <span
          v-if="
            laudo.imagemAnecoica5 ===
            'Apresenta imagem anecóica com debris, medindo'
          "
        >
          {{ laudo.imagemAnecoica5 }} {{ laudo.anecoicaDebrisCm5 }} cm no seu
          maior diâmetro.
        </span>

        <span
          v-if="
            laudo.imagemAnecoica5 ===
            'Apresenta imagem anecóica multiseptada medindo'
          "
        >
          {{ laudo.imagemAnecoica5 }} {{ laudo.anecoicaMultiseptadaCm5 }} cm no
          seu maior diâmetro.
        </span>

        <span
          v-if="
            laudo.imagemAnecoica5 === 'Apresenta imagem heterogênea medindo'
          "
        >
          {{ laudo.imagemAnecoica5 }} {{ laudo.anecoicaHeterogeneaCm5 }} x
          {{ laudo.imagemHeterogeneaCm5 }}cm no seu maior diâmetro.
        </span>

        <span v-if="laudo.imagemAnecoica5 === 'outro.'">
          {{ laudo.imagemHeterogeneaOutro5 }}
        </span>

      </p>

      <p>
        Medidas do ovário esquerdo: {{ laudo.medidasOvarioEsquerdo1 }} x
        {{ laudo.medidasOvarioEsquerdo2 }} x
        {{ laudo.medidasOvarioEsquerdo3 }} cm e volume de cm³.
      </p>

      <p>
        {{laudo.volumeBexiga}} {{laudo.volumeBexigaOutro}}
      </p>

      <p>
        {{laudo.sacoDouglas}}
      </p>
    </folha> -->

    <!-- <folha>


      <p><h4> Impressão: </h4></p>

      <p>{{laudo.impressao1}}</p>



      <p>{{laudo.impressao2}} {{laudo.impressao2Outro}}</p>

      <p>{{laudo.impressao3}} {{laudo.impressao3Outro}}</p>

      <p>{{laudo.impressao4}} {{laudo.impressao4Outro}}</p>

      </folha>
      <folha v-if="laudo.impressao5">

      <p>{{laudo.impressao5}}</p>

      <p>{{laudo.impressao6}}</p>

      <p>{{laudo.impressao7}}</p>

      <p>{{laudo.impressao8}}</p>

      <p>{{laudo.ovarios1}}</p>

      <p>{{laudo.ovarios2}}</p>

      <p>{{laudo.ovarios3}}</p>

    </folha> -->
  </section>
</template>

<script>
import ImprecoesMixins from '@mixins/ImprecoesMixins';
export default {
  mixins: [ImprecoesMixins],
  mounted() {
    let folha = [];
    if (this.$route.query.via) this.via = this.$route.query.via;

    if (this.laudo.uteroPosicao)
      folha.push(`O útero está ${this.laudo.uteroPosicao}, ${this.laudo.uteroSituacao}, tem
        os contornos ${this.laudo.uteroContornos}`);
    if (this.laudo.miometrioEcotextura)
      folha.push(
        `O miométrio tem ecotextura ${this.laudo.miometrioEcotextura}`
      );
    if (this.laudo.presenca)
      folha.push(`Presença de ${this.laudo.presenca} imagens hipoecóicas no miométrio, assim
        distribuídas:`);
    if (this.laudo.ovariosAumentados)
      folha.push(`${this.laudo.distribuicaoImagens} medindo ${this.laudo.medindo} cm,
        localizado na ${this.laudo.localizacao}`);

    if (this.laudo.presencaDivisao) folha.push(`${this.laudo.presencaDivisao}`);
    folha.push(`Diâmetros uterinos:`);
    if (this.laudo.longitudinal)
      folha.push(`Longitudinal: ${this.laudo.longitudinal} cm.`);
    if (this.laudo.anteroPosterior)
      folha.push(`Ântero-posterior: ${this.laudo.anteroPosterior} cm.`);
    if (this.laudo.longitudinal)
      folha.push(`Transverso:  ${this.laudo.transverso} cm.`);
    if (this.laudo.longitudinal) folha.push(`Volume: ${this.laudo.volume} cm.`);

    if (this.laudo.intracavitario)
      folha.push(`${this.laudo.intracavitario}
     ${
       this.laudo.intracavitario === 'Imagem hiperecóica medidindo'
         ? this.laudo.intracavitarioCm
         : ''
     }
      ${
        this.laudo.intracavitario === 'Imagem hiperecóica medidindo'
          ? 'cm, apresentando pedículo vascular ao Doppler.'
          : ''
      }
      ${
        this.laudo.comentariosAdcionais8 ? this.laudo.comentariosAdcionais8 : ''
      }

      `);

    if (this.laudo.endometrioMede)
      folha.push(
        `O endométrio mede ${this.laudo.endometrioMede} mm de espessura.`
      );
    folha.push(`Ovários:`);
    if (this.laudo.ovarioDireito)
      folha.push(`Ovário direito
      ${this.laudo.ovarioDireito}${
        this.laudo.comentariosAdcionais9 ? this.laudo.comentariosAdcionais9 : ''
      },
      ${this.laudo.ovarioDireito2}
      ${this.laudo.comentariosAdcionais ? this.laudo.comentariosAdcionais : ''}
      `);

    if (
      this.laudo.imagemAnecoica4 ===
      'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/ Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
    )
      folha.push(`${this.laudo.imagemAnecoica4} ${this.laudo.imagemAnecoica4Cm} cm no seu
          maior diâmetro.`);

    if (
      this.laudo.imagemAnecoica4 ===
      'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
    )
      folha.push(`${this.laudo.imagemAnecoica4} ${this.laudo.finoSeptoCm4} cm no seu
          maior diâmetro.`);

    if (
      this.laudo.imagemAnecoica4 ===
      'Apresenta imagem anecóica com debris, medindo'
    )
      folha.push(`${this.laudo.imagemAnecoica4} ${this.laudo.finoSeptoCm4} cm no seu
          maior diâmetro.`);

    if (
      this.laudo.imagemAnecoica4 ===
      'Apresenta imagem anecóica multiseptada medindo'
    )
      folha.push(`${this.laudo.imagemAnecoica4} ${this.laudo.anecoicaMultiseptadaCm4} cm no seu
          maior diâmetro.`);

    if (this.laudo.imagemAnecoica4 === 'Apresenta imagem heterogênea medindo')
      folha.push(`${this.laudo.imagemAnecoica4} ${this.laudo.anecoicaHeterogeneaCm45} x
          ${this.laudo.imagemHeterogeneaCm4} cm no seu maior diâmetro.`);

    if (this.laudo.imagemAnecoica4 === 'outro.')
      folha.push(`${this.laudo.imagemHeterogeneaOutro4}`);

    if (this.laudo.medidasOvarioDireito1)
      folha.push(`Medidas do ovário direito: ${this.laudo.medidasOvarioDireito1} x
          ${this.laudo.medidasOvarioDireito2} x ${this.laudo.medidasOvarioDireito4} x ${this.laudo.medidasOvarioDireito3} cm
        e volume de cm³.`);

    if (this.laudo.ovarioEsquerdo)
      folha.push(`Ovário esquerdo
        ${this.laudo.ovarioEsquerdo}${
        this.laudo.ovarioEsquerdoOutro ? this.laudo.ovarioEsquerdoOutro : ''
      },
        ${this.laudo.ovarioEsquerdo2}
        ${
          this.laudo.ovarioEsquerdo2Outro ? this.laudo.ovarioEsquerdo2Outro : ''
        }`);

    if (
      this.laudo.imagemAnecoica5 ===
      'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
    )
      folha.push(`${this.laudo.imagemAnecoica5} ${this.laudo.imagemAnecoica5Cm} cm no seu
          maior diâmetro.`);

    if (
      this.laudo.imagemAnecoica5 ===
      'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
    )
      folha.push(`${this.laudo.imagemAnecoica5} ${this.laudo.finoSeptoCm5} cm no seu
          maior diâmetro.`);

    if (
      this.laudo.imagemAnecoica5 ===
      'Apresenta imagem anecóica com debris, medindo'
    )
      folha.push(`${this.laudo.imagemAnecoica5} ${this.laudo.anecoicaDebrisCm5} cm no seu
          maior diâmetro.`);

    if (
      this.laudo.imagemAnecoica5 ===
      'Apresenta imagem anecóica multiseptada medindo'
    )
      folha.push(`${this.laudo.imagemAnecoica4} ${this.laudo.anecoicaMultiseptadaCm5} cm no seu
          maior diâmetro.`);

    if (this.laudo.imagemAnecoica5 === 'Apresenta imagem heterogênea medindo')
      folha.push(`${this.laudo.imagemAnecoica4} ${this.laudo.anecoicaHeterogeneaCm5} x
          ${this.laudo.imagemHeterogeneaCm5} cm no seu maior diâmetro.`);

    if (this.laudo.imagemAnecoica5 === 'outro.')
      folha.push(`${this.laudo.imagemHeterogeneaOutro5}`);

    if (this.laudo.medidasOvarioEsquerdo1)
      folha.push(`Medidas do ovário esquerdo: ${this.laudo.medidasOvarioEsquerdo1} x
          ${this.laudo.medidasOvarioEsquerdo2} x ${this.laudo.medidasOvarioEsquerdo4} x
          ${this.laudo.medidasOvarioEsquerdo3} cm e volume de cm³.`);

    if (this.laudo.volumeBexiga)
      folha.push(
        `${this.laudo.volumeBexiga} ${
          this.laudo.volumeBexigaOutro ? this.laudo.volumeBexigaOutro : ''
        }`
      );
    if (this.laudo.sacoDouglas) folha.push(`${this.laudo.sacoDouglas}.`);

    if (folha.length > 0) folha.splice(0, 0, '');
    this.utero = [...folha];
    folha = [];
    if (this.laudo.liquidoDouglas1)
      folha.push(`${
        this.laudo.liquidoDouglas1
      } de líquido livre em fundo de saco de
        Douglas. ${
          this.laudo.liquidoDouglas1 === 'Presença' &&
          !!this.laudo.presencaLiqDou
            ? this.laudo.presencaLiqDou
            : ''
        }`);
    if (this.laudo.impressao1) folha.push(`Pelve sem alterações ecográficas.`);
    folha.push('Útero');
    if (this.laudo.impressao2) folha.push(`${this.laudo.impressao2}`);
    if (this.laudo.impressao2 == 'outro') {
      folha.push(`${this.laudo.impressao2Outro}`);
    } else {
      `${this.laudo.impressao2}`;
    }
    if (this.laudo.impressao3) folha.push(`${this.laudo.impressao3}`);
    if (this.laudo.impressao3 == 'outro') {
      folha.push(`${this.laudo.impressao3Outro}`);
    } else {
      `${this.laudo.impressao3}`;
    }

    if (this.laudo.impressao4) folha.push(`${this.laudo.impressao4}`);
    if (this.laudo.impressao4 == 'outro') {
      folha.push(`${this.laudo.impressao4Outro}`);
    } else {
      `${this.laudo.impressao4}`;
    }
    folha.push('Líquido intracavitário');

    if (this.laudo.impressao5) folha.push(`${this.laudo.impressao5}`);
    if (this.laudo.impressao6) folha.push(`${this.laudo.impressao6}`);
    if (this.laudo.impressao7)
      folha.push(
        `Imagem hiperecóica intracavitária (pólipo? Mioma submuccoso?).`
      );
    if (this.laudo.impressao8)
      folha.push(`Endométrio espessado e heterogêneo.`);
    if (this.laudo.ovarios1 || this.laudo.ovarios3) folha.push('Cistos');
    if (this.laudo.ovarios1)
      folha.push(`${this.laudo.ovarios1} ${this.laudo.ovarios2}.`);
    if (this.laudo.ovarios2) folha.push(``);
    if (this.laudo.ovarios3)
      folha.push(`Ovários aumentados de volume com textura polimicrocística.`);

    if (folha.length > 0) folha.splice(0, 0, 'Impressão');
    this.conclusao = [...folha];
    folha = [];

    const todos = [...this.utero, ...this.conclusao];

    while (todos.length > 0) {
      this.tamanhoElemento.push(todos.splice(0, 27));
    }
  },
  data() {
    return {
      utero: [],
      conclusao: [],
      via: '',

      tamanhoElemento: [],
      titulos: [
        'Impressão',
        'Diâmetros uterinos:',
        'Ovários:',
        'Útero',
        'Cistos',
        'Líquido intracavitário',
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$default-spacer: 0px;

section {
  text-align: justify;
  margin-left: 10px;
  font-size: 11pt;
  font-family: 'Calibri';

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    margin: $default-spacer * 0;
    padding: 0px;
    line-height: 15px;
  }

  h4 {
    margin: 0px;
    margin-top: 0px;
    line-height: 5px;
  }

  p {
    text-align: justify;
    font-family: 'Calibri';
    margin-left: -5px;

    margin-top: 5px;
    line-height: 6px;
    padding: 5px;
  }
  br {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    line-height: 100%;
  }
  span {
    margin: $default-spacer * 0;
    margin-top: 15px;
    line-height: 5px;
  }
  footer {
    //position: absolute;
    bottom: 0;
    margin-left: 0px;

    width: 80%;
    height: 100px;
    text-align: left;
    line-height: 20px;
  }
}
</style>
